define("discourse/plugins/swapd-mlm/discourse/templates/connectors/user-card-post-names/invited-by", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.currentUser.admin}}
    {{#if this.siteSettings.referral_enabled}}
      {{#if this.user.invited_by}}
        <div class="earnings-invited-by">
          {{i18n "referral.invited_by"}}
  
          <strong>{{this.user.invited_by.username}}</strong>
          <LinkTo @route="userInvited.show" @models={{array this.user.invited_by.username "earnings"}}>
            {{d-icon "plus"}}
          </LinkTo>
        </div>
      {{/if}}
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "sewbaIRt",
    "block": "[[[41,[30,0,[\"currentUser\",\"admin\"]],[[[41,[30,0,[\"siteSettings\",\"referral_enabled\"]],[[[41,[30,0,[\"user\",\"invited_by\"]],[[[1,\"      \"],[10,0],[14,0,\"earnings-invited-by\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"referral.invited_by\"],null]],[1,\"\\n\\n        \"],[10,\"strong\"],[12],[1,[30,0,[\"user\",\"invited_by\",\"username\"]]],[13],[1,\"\\n        \"],[8,[39,4],null,[[\"@route\",\"@models\"],[\"userInvited.show\",[28,[37,5],[[30,0,[\"user\",\"invited_by\",\"username\"]],\"earnings\"],null]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,6],[\"plus\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[]],null]],[],false,[\"if\",\"div\",\"i18n\",\"strong\",\"link-to\",\"array\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/swapd-mlm/discourse/templates/connectors/user-card-post-names/invited-by.hbs",
    "isStrictMode": false
  });
});