define("discourse/plugins/swapd-mlm/discourse/components/modal/earnings-edit-transaction", ["exports", "@ember/component", "discourse-common/utils/decorators", "discourse/lib/ajax", "@ember/string", "@ember/utils", "@ember/object", "@ember/template-factory"], function (_exports, _component, _decorators, _ajax, _string, _utils, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal @closeModal={{@closeModal}} id="earnings-modal" @title={{this.modalTitle}} class="close-topic-modal">
    <:body>
      {{#if this.editTransaction}}
        <section>
          <Input
            placeholder={{(i18n "referral.price")}}
            @value={{this.value1}}
            />
        </section>
      {{/if}}
  
      <section>
        <Textarea
          @value={{this.value2}}
          placeholder={{this.placeholderValue2}}
          rows="5"/>
      </section>
    </:body>
  
    <:footer>
      <DButton
        @action={{action "save"}}
        @disabled={{this.saveDisabled}}
        class="btn-large btn-primary"
        @label="referral.save"
      />
  
      {{#if this.isEdit}}
        <DButton
          class="btn-large btn-danger"
          @disabled={{this.loading}}
          @action={{action "delete"}}
          @label="referral.delete"
        />
      {{/if}}
    </:footer>
  </DModal>
  
  */
  {
    "id": "9D/5kG04",
    "block": "[[[8,[39,0],[[24,1,\"earnings-modal\"],[24,0,\"close-topic-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[30,0,[\"modalTitle\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n\"],[41,[30,0,[\"editTransaction\"]],[[[1,\"      \"],[10,\"section\"],[12],[1,\"\\n        \"],[8,[39,4],[[16,\"placeholder\",[28,[37,5],[\"referral.price\"],null]]],[[\"@value\"],[[30,0,[\"value1\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,\"section\"],[12],[1,\"\\n      \"],[8,[39,6],[[16,\"placeholder\",[30,0,[\"placeholderValue2\"]]],[24,\"rows\",\"5\"]],[[\"@value\"],[[30,0,[\"value2\"]]]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,8],[[24,0,\"btn-large btn-primary\"]],[[\"@action\",\"@disabled\",\"@label\"],[[28,[37,9],[[30,0],\"save\"],null],[30,0,[\"saveDisabled\"]],\"referral.save\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"isEdit\"]],[[[1,\"      \"],[8,[39,8],[[24,0,\"btn-large btn-danger\"]],[[\"@disabled\",\"@action\",\"@label\"],[[30,0,[\"loading\"]],[28,[37,9],[[30,0],\"delete\"],null],\"referral.delete\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[\"@closeModal\"],false,[\"d-modal\",\":body\",\"if\",\"section\",\"input\",\"i18n\",\"textarea\",\":footer\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/swapd-mlm/discourse/components/modal/earnings-edit-transaction.hbs",
    "isStrictMode": false
  });
  class EarningsEditTransaction extends _component.default {
    init() {
      super.init(...arguments);
      this.set("loading", false);
      (0, _string.w)("type value1 value2 user_id").forEach(k => {
        this.set(k, this.get(`model.${k}`));
      });
    }
    editTransaction(type) {
      return type === 1;
    }
    static #_ = (() => dt7948.n(this.prototype, "editTransaction", [(0, _decorators.default)("type")]))();
    isEdit(id) {
      return !(0, _utils.isBlank)(id);
    }
    static #_2 = (() => dt7948.n(this.prototype, "isEdit", [(0, _decorators.default)("model.id")]))();
    placeholderValue2(editTransaction) {
      const key = editTransaction ? "title" : "comment";
      return I18n.t(`referral.${key}`);
    }
    static #_3 = (() => dt7948.n(this.prototype, "placeholderValue2", [(0, _decorators.default)("editTransaction")]))();
    modalTitle(editTransaction, isEdit) {
      const action = isEdit ? "edit" : "add";
      const suffix = editTransaction ? "transaction" : "comment";
      return I18n.t(`referral.${action}_${suffix}`);
    }
    static #_4 = (() => dt7948.n(this.prototype, "modalTitle", [(0, _decorators.default)("editTransaction", "isEdit")]))();
    saveDisabled(loading, editTransaction, formatedPrice, value1, value2) {
      if (loading) return true;
      if (editTransaction) return isNaN(formatedPrice) || (0, _utils.isBlank)(value2);
      return (0, _utils.isBlank)(value1) || (0, _utils.isBlank)(value2);
    }
    static #_5 = (() => dt7948.n(this.prototype, "saveDisabled", [(0, _decorators.default)("loading", "editTransaction", "formatedPrice", "value1", "value2")]))();
    formatedPrice(value1) {
      return parseInt(value1 || "");
    }
    static #_6 = (() => dt7948.n(this.prototype, "formatedPrice", [(0, _decorators.default)("value1")]))();
    save() {
      if (this.get("saveDisabled")) return;
      this.set("loading", true);
      this.get("isEdit") ? this._update() : this._save();
    }
    static #_7 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
    delete() {
      if (this.get("loading")) return;
      this.set("loading", true);
      this._delete();
    }
    static #_8 = (() => dt7948.n(this.prototype, "delete", [_object.action]))();
    _save() {
      (0, _ajax.ajax)("/earnings", {
        type: "POST",
        data: this._serializeData()
      }).then(result => this.appEvents.trigger("earnings:add", result)).finally(() => this.set("loading", false));
    }
    _update() {
      const id = this.get("model.id");
      (0, _ajax.ajax)(`/earnings/${id}`, {
        type: "PUT",
        data: this._serializeData()
      }).then(result => this.appEvents.trigger("earnings:update", result)).finally(() => this.set("loading", false));
    }
    _delete() {
      const id = this.get("model.id");
      (0, _ajax.ajax)(`/earnings/${id}`, {
        type: "DELETE",
        data: this._serializeData()
      }).then(result => this.appEvents.trigger("earnings:delete", result)).finally(() => this.set("loading", false));
    }
    _serializeData() {
      return {
        user_id: this.get("user_id"),
        data: {
          type: this.get("type"),
          value1: typeof this.get("value1") === 'string' ? this.get("value1").replace(',', '.') : this.get("value1"),
          value2: this.get("value2")
        }
      };
    }
  }
  _exports.default = EarningsEditTransaction;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EarningsEditTransaction);
});