define("discourse/plugins/swapd-mlm/discourse/templates/components/user-earnings-edit-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{input
    value=this.valueCopy}}
  
  {{d-button
    icon="far-save"
    disabled=this.saveDisabled
    action=(action "doSave")
    class="btn-primary"}}
  
  {{d-button
    icon="times"
    disabled=this.disabled
    action=(action "doCancel")
    class="btn-danger"}}
  
  */
  {
    "id": "n4AKNmAN",
    "block": "[[[1,[28,[35,0],null,[[\"value\"],[[30,0,[\"valueCopy\"]]]]]],[1,\"\\n\\n\"],[1,[28,[35,1],null,[[\"icon\",\"disabled\",\"action\",\"class\"],[\"far-save\",[30,0,[\"saveDisabled\"]],[28,[37,2],[[30,0],\"doSave\"],null],\"btn-primary\"]]]],[1,\"\\n\\n\"],[1,[28,[35,1],null,[[\"icon\",\"disabled\",\"action\",\"class\"],[\"times\",[30,0,[\"disabled\"]],[28,[37,2],[[30,0],\"doCancel\"],null],\"btn-danger\"]]]],[1,\"\\n\"]],[],false,[\"input\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/swapd-mlm/discourse/templates/components/user-earnings-edit-form.hbs",
    "isStrictMode": false
  });
});